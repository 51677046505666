@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  color: #202124;
}

.main,
main {
  position: relative;
}

a:focus {
  color: #1a0dab;
}

.main.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto 0;
}
.main.home .search-container {
  max-width: 600px;
  padding-bottom: 10vh;
  flex:1;
}

.top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  font-size: 13px;
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  color: rgba(0, 0, 0, 0.87);
}

.top-menu-item a {
  text-decoration: none;
  color: #5f6368;
}

.top-menu-item a:hover {
  text-decoration: underline;
}

.search-btns {
  padding-top: 80px;
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
}

.search-btn {
  border: 1px solid transparent;
  outline: none;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 15px;
  background: #f8f9fa;
  color: #555;
  cursor: pointer;
}

.search-btn:hover {
  border: 1px solid #eee;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .search-btns, .no-show-mobile {
    display: none;
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    font-size: 13px;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
}

.main.home .search-container {
  max-width: 100%;
  padding-bottom: 30vh;
}

.search-input {
  height: 40px !important;
}
}

@media screen and (max-width: 500px) {



}



/* .footer-links-section button {
  font-size: 14.5px;
}

.footer-links-section a:hover,
.footer-links-section button:hover {
  text-decoration: underline;
}

.settings-dropdown {
  position: relative;
  display: inline-block;
}

.settings-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #333;
  z-index: 1;
  bottom: 30px;
  right: 0;
}

.settings-dropdown-content a {
  color: #555;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dark-mode-btn {
  border-top: 1px solid #eee !important;
}

.settings-dropdown-content a:hover {
  text-decoration: underline;
}

.settings-dropdown:hover .settings-dropdown-content {
  display: block !important;
} */
