@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500&family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  color: #202124;
}

.main,
main {
  position: relative;
}

a:focus {
  color: #1a0dab;
}

.main.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto 0;
}
.main.home .search-container {
  max-width: 600px;
  padding-bottom: 10vh;
  flex:1 1;
}

.top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  font-size: 13px;
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  right: 0;
  color: rgba(0, 0, 0, 0.87);
}

.top-menu-item a {
  text-decoration: none;
  color: #5f6368;
}

.top-menu-item a:hover {
  text-decoration: underline;
}

.search-btns {
  padding-top: 80px;
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
}

.search-btn {
  border: 1px solid transparent;
  outline: none;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 15px;
  background: #f8f9fa;
  color: #555;
  cursor: pointer;
}

.search-btn:hover {
  border: 1px solid #eee;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .search-btns, .no-show-mobile {
    display: none;
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    font-size: 13px;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
}

.main.home .search-container {
  max-width: 100%;
  padding-bottom: 30vh;
}

.search-input {
  height: 40px !important;
}
}

@media screen and (max-width: 500px) {



}



/* .footer-links-section button {
  font-size: 14.5px;
}

.footer-links-section a:hover,
.footer-links-section button:hover {
  text-decoration: underline;
}

.settings-dropdown {
  position: relative;
  display: inline-block;
}

.settings-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #333;
  z-index: 1;
  bottom: 30px;
  right: 0;
}

.settings-dropdown-content a {
  color: #555;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dark-mode-btn {
  border-top: 1px solid #eee !important;
}

.settings-dropdown-content a:hover {
  text-decoration: underline;
}

.settings-dropdown:hover .settings-dropdown-content {
  display: block !important;
} */

body {
  margin: 0;
  width: 100%;
}

.all-results-container {
  margin-left: 180px;
  max-width: 650px;
  margin-bottom: 20px;
  min-height: 80vh;
}

@media screen and (max-width: 768px) {
  .filter-menu-items {
    margin-left: 0;
  }

  .filter-menu-item .icon {
    display: none;
  }
  body {
    overflow-x: hidden;
  }
}

.menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.menu-section {
  display: flex;
  align-items: center;
  padding: 20px;
}

.menu-section .logo-text{
  cursor: pointer;
}

.menu-section .logo-text {
  font-size: 1rem;
  letter-spacing: -0.1rem;
}

.menu-section .menu-search {
  width: 700px;
}
.menu-section .menu-search .search-cont {
  top: -25px;
  left: 40px;
}

.fa-cog {
  font-size: 20px;
  color: #555;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.fa-cog:hover {
  background: #eee;
}

.header-profile-icons {
  display: flex;
}

@media screen and (max-width: 768px) {
  .menu-section {
    flex-direction: column;
  }
  .search-btns {
    display: none;
  }

  .header-profile-icons {
    display: none;
  }
  .topmenu-menu-search .search-cont {
    width: 100%;
    position: relative !important;
  }

  .menu-section {
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .menu-section .topmenu-menu-search .search-cont {
    top: 0;
    left: 0;
  }

  .menu-section .menu-search {
    width: 98vw;
  }
}

.logo-text {
  font-size: 2.7rem;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: -0.2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: default;
}
.logo-text h1 {
  line-height: 1rem;
}
.blue {
  color: #4285f4;
}
.red {
  color: #ea4335;
}
.yellow {
  color: #fcbc05;
}
.green {
  color: #34a852;
}

@media screen and (max-width: 500px) {
  .search-logo {
    margin-bottom: 10px;
  }
  .frontpage-logo .logo-text {
    font-size: 1.8rem;
    letter-spacing: -0.1rem;
  }
}
/* Select Search */

.search-box {
  position: relative;
  margin: 0 20px;
}
.fa {
  position: absolute;
  z-index: 2;
  font-size: 15px;
  color: #aaa;
}
.fa-search-left {
  top: 15px;
  left: 20px;
  display: none;
}

.searchbox-icons {
  position: absolute;
  z-index: 2;
 font-size: 20px;
  top: 6px;
  right: 10px;
  display: flex;
  align-items: center;
}

.searchbox-icons .si {
  cursor: pointer;
  line-height: 20px;
  padding: 6px 10px;
}

.searchbox-icons .clear-icon {
  position: relative !important;
  border-right: 1px solid #eee;
}

.searchbox-icons .fa-search-right {
  color: #4285F4;
}
.clear-icon {
  color: #777;
  display: none;
}

/**
 * Main wrapper
 */
.search-cont {
  width: 692px;
  position: absolute;
}
.search {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 30px;
  position: relative;
  background: #fff;
}

.search:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.search *,
.search *::after,
.search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.search-value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}



.topmenu-search-option:last-child {
  margin-bottom: 22px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.remove-btn:hover {
  text-decoration: underline;
  color: blue !important;
}

/* Select Search */

.mobile-search-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 5;
  overflow: hidden;
  display: none;
}

.mobile-search-select {
  display: block !important;
}

.delete-icon {
  position: relative;
  color: #777;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
}

.mobile-search-cont {
  width: 100%;
  height: 100%;
}

.mobile-search-select {
  width: 100%;
  height: 100%;
}

.mobile-search-value {
  width: 98%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  margin-left: 10px;
}

.mobile-search-value input {
  flex: 1 1;
  border: none;
  font-size: 16px;
}

.mobile-search-value input:focus {
  border: none;
  outline: none;
}
.searchbar-icon {
  padding: 20px;
  font-size: 20px;
  cursor: pointer;
  color: blue;
}

.mobile-clear-icon {
  position: absolute;
  right: 8px;
  color: #777;
}

.mobile-search-option span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 10px;
}

.clock-icon {
  font-size: 20px;
  margin-right: 20px;
  margin-left: 10px;
  color: #333;
}

.mobile-search-option a {
  color: #333;
  text-decoration: none;
}

/* Drop down */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content-cont {
  display: none;
  position: absolute;
  background-color: #ffff;
  width: 300px;
  height: 400px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
  border-radius: 10px;
  z-index: 3;
  right: -10px;
  padding: 20px;
  top: 57px;
}

.dropdown-hide {
  display: none;
}

.dropdown-show {
  display: block;
}

.drop-item {
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}
.drop-link {
  width: 84px;
  height: 84px;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  color: #555;
  margin-bottom: 10px;
}
.drop-link img {
  width: 35px;
  margin: auto;
  margin-top: 10px;
}
.drop-link p {
  color: #202124;
  display: inline-block;
  font-family: 'Roboto',Helvetica,Arial,sans-serif;
  font-size: 14px;
  letter-spacing: 0.09px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 76px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.drop-link:hover {
  background-color: #edf7fc;
}

.dropdown:hover .profile-hightlight-dropdown {
  display: block;
}

.fa-th:hover {
  background: #eee;
}


.profile-pic {
  width: 35px;
  height: 35px;
  border: 3px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
}
.fa-th {
  font-size: 20px;
  color: #5f6368 !important;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.profile .profile-hightlight-dropdown {
  display: none;
  position: absolute;
  background-color: #333;
  width: auto;
  height: auto;
  box-shadow: none;
  border: none;
  border-radius: 5px;
  z-index: 3;
  right: 0;
  top: 45px;
  padding: 5px;
  color: #aaa;
}

.profile-pic:hover {
  border-color: #eee;
}

.profile-hightlight-dropdown p:nth-of-type(1) {
  color: #fff;
}

.profile-hightlight-dropdown p {
  font-size: 12px;
  margin: 2px;
}

.profile .profile-details-dropdown {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 50px;
  padding: 5px;
  color: #aaa;
  width: 350px;
  height: auto;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  right: 5px;
}

.profile-details-hide {
  display: none;
}

.profile-details-show {
  display: block;
}

.first-detail {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.profile-details-dropdown .first-detail img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
}

.first-detail .detail-text {
  font-size: 14px;
  color: #555;
  margin: 2px;
}

.first-detail .detail-text:nth-of-type(1) {
  color: #111;
  font-size: 17px;
}

.first-detail a {
  padding: 10px 15px;
  text-decoration: none;
  border: 1px solid #eee;
  color: #333;
  line-height: 50px;
  border-radius: 30px;
}

.first-detail a:hover {
  background: #f8f8f8;
}

.second-detail {
  display: flex;
  align-items: center;
  padding: 6px 30px;
  border-bottom: 1px solid #eee;
  color: #555;
  text-decoration: none;
}

.second-detail:hover {
  background: #f8f8f8;
}
.second-detail .fa-user-plus {
}
.second-detail p {
  margin-left: 15px;
}

.third-detail {
  padding: 25px 20px;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.third-detail a {
  text-decoration: none;
  padding: 10px 20px;
  color: #333;
  border: 1px solid #eee;
  border-radius: 5px;
}

.third-detail a:hover {
  background: #f8f8f8;
}

.fourth-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.fourth-detail * {
  text-decoration: none;
  padding: 5px;
  color: #555;
  font-size: 13px;
  border-radius: 5px;
}

.fourth-detail a:hover {
  background-color: #f8f8f8;
}

.result-count {
  font-size: 14px;
  color: #666;
}

.result-card {
  padding: 2px 0;
}

.result-card a {
  text-decoration: none;
  color: #1a0dab;
}

.result-card a:hover h3 {
  text-decoration: underline;
}

.result-card a h3 {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  display: inline-block;
line-height: 1.3;
font-size: 20px;
}

.result-card a p {
  color: #202124;
  margin-bottom: 7px;
  font-size: 14px;
}

.result-card a p span {
  color: #5f6368;
}

.result-card a h3 {
  white-space: normal;
  text-overflow: inherit;
}

.result-card .excerpt {
  color: #4d5156;
line-height: 1.58;
  margin-top: 0;
  font-size: 14px;
}

@supports (-webkit-line-clamp: 2) {
  .excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: 768px) {
  .result-count {
    display: none;
  }
  .all-results-container {
    margin-left: 0;
    max-width: 100%;
  }
  .all-container, .results-content {
    background: #eee;
  }

  .result-card {
    background: #fff;
    padding: 2px 20px;
    margin: 8px 0;
    border-bottom: 1px solid #ddd;
  }

  .excerpt {
    color: #555;
    margin-top: 8px;
    white-space: normal;
    text-overflow: initial;
  }

  @supports (-webkit-line-clamp: 3) {
    .excerpt {
      -webkit-line-clamp: 3;
    }
  }
}

.footer {
  position: relative;
  width: 100%;
}

.footer .country {
  background: #eee;
  padding: 15px 40px;
  color: #666;
  font-size: 15px;
}

.footer-links {
  background: #eee;
  border-top: 1px solid #ccc;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.footer-links-section {
  display: flex;
  align-items: center;
}

.footer-links-section a,
.footer-links-section button {
  text-decoration: none;
  color: #666;
  padding: 0 18px;
  border: none;
  background: none;
  cursor: pointer;
}
.footer-links-section button {
  font-size: 14.5px;
}

.footer-links-section a:hover,
.footer-links-section button:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer .country {
    padding: 10px 20px;
    display: flex;
    justify-items: center;
  }

  .footer .country p {
    font-size: 16px;
  }
  .footer-links {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .footer-links-section {
    padding: 10px 0;
  }

  .footer-links-section a,
  .footer-links-section button {
    font-size: 13px;
  }
}


.filter-menu-items {
  margin-left: 180px;
}
.filter-menu {
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.filter-menu-item {
  color: #5f6368;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  padding: 10px 2px;
  margin: 0 10px;
}

.filter-menu-item.item-active {
  color: blue;
  border-bottom: 3px solid blue;
}

@media screen and (max-width: 768px) {
  .filter-menu {
    padding: 0 10px;
  }

  .filter-menu-items {
    margin-left: 0;
  }

  .filter-menu-item .icon {
    display: none;
  }

  .filter-menu-item.item-active {
    color: black;
    border-bottom: 3px solid black;
  }
}

/* Select Search */

.search-box {
  position: relative;
  margin: 0 20px;
}
.fa {
  position: absolute;
  z-index: 2;
  font-size: 15px;
  color: #aaa;
}
.fa-search {
  top: 15px;
  left: 20px;
}
.clear-icon {
  color: #777;
  font-size: 20px;
  top: 0;
  right: 0;
  padding: 15px;
  cursor: pointer;
  display: none;
}

/**
 * Main wrapper
 */
.search-cont {
  width: 100%;
  position: absolute;
}
.search {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 30px;
  position: relative;
  background: #fff;
}

.search:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.search *,
.search *::after,
.search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.search-value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.search-input {
  display: block;
  height: 46px;
  width: 98%;
  margin: auto;
  padding: 0 40px 0 36px;
  background: #fff;
  border: none;
  border-radius: 30px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.search-input::-webkit-search-decoration,
.search-input::-webkit-search-cancel-button,
.search-input::-webkit-search-results-button,
.search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.search-input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.search-select {
  display: none;
}

/**
* Options
*/
.search-options {
  list-style: none;
  padding-left: 0;
  border-top: 1px solid #eee;
  background: #eee;
  margin-top: 0px;
}

/**
* Option
*/
.search-option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-align: left;
  cursor: default;
  color: #784ca4;
}

.search-option:hover {
  background: #eee;
}

.search-option a {
  text-decoration: none;
  padding-right: 40%;
  line-height: 30px;
}
.remove-btn:hover {
  text-decoration: underline;
  color: blue !important;
}

.accordion-container {
  background: #fff;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
  border-top: 1px solid #eee !important;
}
.MuiPaper-elevation1:last-of-type {
  border-bottom: 1px solid #eee !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  display: flex !important;
  padding: 0 !important;
  min-height: 48px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 34px !important;
}
.MuiAccordionDetails-root {
  display: flex !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

@media screen and (max-width: 768px) {
  .accordion-container {
    width: 100%;
    padding-top: 10px;
  }

  .accordion-sub-container {
    margin: 0 20px;
  }

  .accordion-container h2 {
    margin-top: 10px;
  }
}

.suggest {
  margin-top: 40px;
}

.suggest p {
  font-size: 18px;
  color: #ea4335;
}

.suggestions a {
  padding-right: 15px;
  text-decoration: none;
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .notfound-page {
    padding: 0 30px;
  }
}

.result-count {
  font-size: 14px;
  color: #666;
}

.projects-card {
  padding: 2px 0;
  margin-bottom: 20px;
}

.projects-card a {
  text-decoration: none;
}

.projects-card a:hover h3 {
  text-decoration: underline;
}

.projects-card a h3 {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.projects-card a p {
  color: #111;
  margin-bottom: 7px;
  font-size: 14px;
}

@supports (-webkit-line-clamp: 2) {
  .projects-excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.projects-details {
  display: flex;
  align-items: center;
  width: 100%;
}
.projects-img-container {
  width: 130px;
  margin-right: 20px;
  height: 70px;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 10px;
}
.projects-img-container img {
  width: 100%;
}

.projects-text-container {
  flex: 1 1;
  font-size: 15.5px;
}

.projects-excerpt {
  color: #333;
  margin-top: 7px;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.projects-tools {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  color: #555;
}

@media screen and (max-width: 768px) {
  .projects-content {
    background-color: #fff;
    margin-top: 10px;
  }
  .projects-card {
    margin-bottom: 0;
    padding: 15px 15px;
    padding-top: 0;
    border-bottom: 1px solid #eee;
  }
  .project-link p {
    display: none;
  }
}

.images-content {
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  overflow-y: hidden;
  justify-content: space-between;
}
.images--card {
  width: 350px;
  height: 280px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.images--img-container {
  height: 200px;
  overflow: hidden;
  width: 95%;
  background: #eee;
  margin: auto;
}

.images--img-container:hover {
  box-shadow: 2px 2px 10px #ddd;
}

.images--img-container img {
  height: auto;
  width: 100%;
}
.images--text-container {
  text-align: left;
  margin-left: 10px;
  color: #333;
  text-decoration: none;
  font-size: 15px;
}
.images--name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}
.images--link {
  font-size: 14px;
  margin: 0;
  color: #777;
}

@media screen and (max-width: 810px) {
  .images--card {
    width: 320px;
  }
  .images-content {
    justify-content: center;
  }
}

.result-count {
  font-size: 14px;
  color: #666;
}

.blog-card {
  padding: 2px 0;
  margin-bottom: 20px;
  display: flex;
  color: #333;
  text-decoration: none;
  border: 1px solid #eee;
  padding: 0 20px;
  border-radius: 10px;
  align-items: center;
}

.blog-text-container {
  flex: 1 1;
}

.category {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.category p {
  margin-left: 10px;
  color: #70757a;
font-family: arial,sans-serif;
font-size: 12px;
line-height: 1.3333333333333333em;
}

.blog-card h3 {
  margin: 0;
  font-weight: normal;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-card:hover h3 {
  color: blue;
}

.blog-excerpt {
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #70757a;
  font-size: 14px;
line-height: 1.45em;
}

@supports (-webkit-line-clamp: 3) {
  .blog-excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .blog-card h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.blog-date {
  margin-top: 5px;
  font-size: 12px;
  line-height: 1.3333333333333333em;
  color: #70757a;
}

.blog-img-container {
  width: 110px;
  height: 110px;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 10px;
  align-self: flex-start;
  margin-top: 20px;
  border-radius: 20px;
}

.blog-img-container img {
  width: auto;
  height: 100%;
}

.blog-icon {
  width: 20px;
}

@media screen and (max-width: 768px) {
  .blogpage-container {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .blog-card {
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  }

  .blog-card h3 {
    max-width: 100%;
    white-space: normal;
    text-overflow: initial;
    margin-bottom: 30px;
  }

  .blog-card .blog-excerpt {
    display: none;
  }

  .blog-img-container {
    width: 100px;
  height: 100px;
    align-self: center;
    margin-top: 0;
  }
}

