.result-count {
  font-size: 14px;
  color: #666;
}

.projects-card {
  padding: 2px 0;
  margin-bottom: 20px;
}

.projects-card a {
  text-decoration: none;
}

.projects-card a:hover h3 {
  text-decoration: underline;
}

.projects-card a h3 {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.projects-card a p {
  color: #111;
  margin-bottom: 7px;
  font-size: 14px;
}

@supports (-webkit-line-clamp: 2) {
  .projects-excerpt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.projects-details {
  display: flex;
  align-items: center;
  width: 100%;
}
.projects-img-container {
  width: 130px;
  margin-right: 20px;
  height: 70px;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 10px;
}
.projects-img-container img {
  width: 100%;
}

.projects-text-container {
  flex: 1;
  font-size: 15.5px;
}

.projects-excerpt {
  color: #333;
  margin-top: 7px;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.projects-tools {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  color: #555;
}

@media screen and (max-width: 768px) {
  .projects-content {
    background-color: #fff;
    margin-top: 10px;
  }
  .projects-card {
    margin-bottom: 0;
    padding: 15px 15px;
    padding-top: 0;
    border-bottom: 1px solid #eee;
  }
  .project-link p {
    display: none;
  }
}
